import { Injectable } from '@angular/core';
import { filterNullUndefined } from '@common/util-foundation';
import {
  ApplianceDetailsConfig,
  DiscountStoreConfig,
  QuotesConfig,
  ClientConfig,
  TrustBoxClientConfig,
  SafeHandsClientConfig,
} from '@common/util-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FeatureConfigState } from './feature-config.reducers';
import * as FeatureConfigSelectors from './feature-config.selectors';
import {
  getSafeHandsClientConfig,
  getTrustBoxClientConfig,
} from './feature-config.selectors';

@Injectable()
export class FeatureConfigFacade {
  remoteStateLoaded$: Observable<boolean> = this.store
    .select(FeatureConfigSelectors.getRemoteStateLoaded)
    .pipe(filter((loaded: boolean) => loaded));

  discountStore$: Observable<
    DiscountStoreConfig | undefined
  > = this.store.select(FeatureConfigSelectors.getDiscountStoreConfig);

  applianceDetails$: Observable<ApplianceDetailsConfig> = this.store
    .select(FeatureConfigSelectors.getApplianceDetailsConfig)
    .pipe(filterNullUndefined());

  quotes$: Observable<QuotesConfig> = this.store
    .select(FeatureConfigSelectors.getQuotesConfig)
    .pipe(filterNullUndefined());

  isEngineerJourney$: Observable<boolean> = this.store
    .select(FeatureConfigSelectors.isEngineerJourney)
    .pipe(filterNullUndefined());

  trustBoxClientConfig$: Observable<
    TrustBoxClientConfig | undefined
  > = this.store.select(getTrustBoxClientConfig);

  safeHandsClientConfig$: Observable<
    SafeHandsClientConfig | undefined
  > = this.store.select(getSafeHandsClientConfig);

  constructor(private store: Store<FeatureConfigState>) {}

  clientConfig$<T extends ClientConfig>(
    filterNullAndUndefinedValues: boolean = true
  ): Observable<T> {
    const clientConfig$ = this.store.select(
      FeatureConfigSelectors.getClientConfig
    ) as Observable<T>;

    return filterNullAndUndefinedValues
      ? clientConfig$.pipe(filterNullUndefined())
      : clientConfig$;
  }
}
