import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PERSONAL_DETAILS_FEATURE_KEY } from './personal-details.reducer';

import {
  CorrespondenceAddress,
  PersonalAddress,
  PersonalDetails,
  PersonalDetailsState,
  YourDetails,
} from '@common/util-models';

export const getPersonalDetailsState = createFeatureSelector<PersonalDetailsState>(
  PERSONAL_DETAILS_FEATURE_KEY
);

export const getAddress = createSelector(
  getPersonalDetailsState,
  (state: PersonalDetailsState) => state.address
);

//todo: handle for loggedin user..
export const getDirectDebit = createSelector(
  getPersonalDetailsState,
  (state: PersonalDetailsState) => state.directDebitDetails
);

export const getMarketingPreferences = createSelector(
  getPersonalDetailsState,
  (state: PersonalDetailsState) => state.marketingPreferences
);

export const getPreferredPaymentDay = createSelector(
  getPersonalDetailsState,
  (state: PersonalDetailsState) => state.directDebitDetails?.monthlyPaymentDay
);

export const getPersonalDetails = createSelector(
  getPersonalDetailsState,
  (state: PersonalDetailsState) => {
    return {
      title: state.title,
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      mobileNumber: state.mobileNumber,
    } as PersonalDetails;
  }
);

export const getPaperlessPlanDocumentPreference = createSelector(
  getPersonalDetailsState,
  (state: PersonalDetailsState) => state.paperlessPlanDocuments
);

export const isPaperlessPlanDocumentPreferenceSet = createSelector(
  getPersonalDetailsState,
  (state: PersonalDetailsState) => state.paperlessPlanDocuments !== undefined
);

export const isMarketingPreferenceSet = createSelector(
  getPersonalDetailsState,
  (state: PersonalDetailsState) => state.marketingPreferences !== undefined
);

export const getPersonalDetailsWithAddress = createSelector(
  getPersonalDetails,
  getAddress,
  (personalDetails: PersonalDetails, address: PersonalAddress | undefined) => {
    return {
      name: `${personalDetails.title} ${personalDetails.firstName} ${personalDetails.lastName}`,
      email: personalDetails.email,
      mobile: personalDetails.mobileNumber,
      landline: '',
      address: {
        addressLine1: format(address?.line1),
        addressLine2: format(address?.line2),
        addressLine3: format(address?.city),
        addressLine4: format(address?.county),
        countryCode: '',
        postalCode: format(address?.postcode),
      } as CorrespondenceAddress,
    } as YourDetails;
  }
);

function format(input?: string) {
  return (input ?? '').trim();
}
